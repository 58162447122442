import * as React from 'react';

import { Layout, SignUp } from '../../components';

const SignUpPage = () => {
  return (
    <>
      <Layout.NavMenu solidColor notFixed />
      <div className="d-flex align-items-start flex-column" style={{ height: '90vh' }}>
        <div className="mb-auto w-100">
          <SignUp.SignUpProvider>
            <SignUp.Stepper />
            <SignUp.Intro />
            <SignUp.Step1 />
            <SignUp.Step2 />
            <SignUp.Step3 />
            <SignUp.Step4 />
            <SignUp.Step5 />
          </SignUp.SignUpProvider>
        </div>
        <Layout.Footer />
      </div>
    </>
  );
};

export default SignUpPage;

export const Head = () => <Layout.PageHead subtitle="Sign Up" googleNoIndex />;
